.awssld {
    --slider-height-percentage: 19%;
    --slider-transition-duration: 0ms;
    --organic-arrow-thickness: 3px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 13px;
    --organic-arrow-color: black;
    --control-button-width: 7%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #fde8d5;
    --control-bullet-active-color: #fde8d5;
    --loader-bar-color: #fde8d5;
    --loader-bar-height: 5px;
    }
    @media all and (max-width: 768px) {
        .awssld {
            /* --slider-transition-duration: 300ms; */
            --slider-height-percentage: 23%;
            pointer-events:none;
        }
    }
.awssld__content{
    background-color: white;
}
.awssld__timer{
    visibility: hidden;
}
.awssld__next{
    visibility: hidden;
}
.awssld__prev{
    visibility: hidden;
}
.awssld__wrapper{
    padding-top:20px;
}