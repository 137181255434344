/* Fonts */
@font-face {
  font-family: 'Godo';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/GodoM.woff') format('woff');
  font-display: swap
}

/* FOR IE SETTING */
main {
  display: block;  /* new */
}

a {
  text-decoration: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
